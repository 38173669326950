:root {
  --primaryColorDark: #29386c;
  --primaryColorDark1: #17275e;
  --primaryColor: #264f81;
  --primaryColor1: #ecaa51;
  --primaryColor2: #fce9d0;
  --primaryColor3: #cf9649;
  --primaryColor4: #088ae9;
  --bannerColor: #335dff;
  --fontFamily: "Readex Pro", sans-serif;
  --templateColor: #00bfff;
  --templateColor2: #ff8a73;
  --whiteColor: #ffffff;
  --lightColor: #f8f8fc;
  --lightColor1: #f7f7f7;
  --darkColor: #7c7c80;
  --blackColor: #000000;
  --paragraphColor: #818992;
  --transition: 0.5s;
  --darkColor1: #474747;
  --blue: #4a50ff;
  --blueActive: #7d81f4;
  --pink: #d74a96;
  --pinkHover: #f076b8;
  --blueSky: #1b95ed;
  --lightBlue: #1999d6;
  --lightBlueHover: #1763fb;
  --mint: #24b6b1;
  --mintHover: #2c9491;
  --green: #47821c;
  --greenHover: #5bb01c;
  --purpule: #7b70ff;
  --purpleHover: #5145e2;
  --yellow: #ffc736;
  --yellowHover: #fbb911;
  --breadcrumbtextcolor: #d1c4e9;
  --breadcrumbtextcoloractive: #fff;
  --textBlack: #2e3233;
  --grey50: #737c80;
  --grey40: #5c6366;
  --grey10: #f2f2f2;
  --grey20: #d2d2d2;
  --grey60: #8a9599;
  --grey70: #b8c7cc;
  --grey80: #a1aeb3;
  --grey30: #454b4d;
  --lightBlue1: #d9efff;
  --lightBlue2: #cfe0e6;
  --lightOrange: #ffa526;
  --darkColor2: #454b4d;
  --textColor: "#0B1B35";
  --primaryGardient: linear-gradient(89.89deg, #00b2ff 0.09%, #33c1ff 98.44%);
  --primaryGardient1: linear-gradient(89.89deg, #008ecc 0.09%, #00a2e9 98.44%);
  --blueLightHover: #51729a;
  --blueDarkHover: #1f4370;
}

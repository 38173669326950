@import "variables";

.ant-modal-root {
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-header {
        border-bottom: none;
        border-radius: 20px 20px 0 0;
      }

      .ant-modal-content {
        background: #ffffff;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        padding: 12px;

        .ant-modal-close-x {
          .ant-modal-close-icon {
            svg {
              fill: #33aaff;
            }
          }
        }

        .ant-modal-body {
          .ant-modal-confirm-body-wrapper {
            .ant-modal-confirm-btns {
              .ant-btn {
                &.ant-btn-default {
                  border: none;
                  color: var(--textBlack);
                  border-radius: 12px;
                  background-color: var(--grey10);
                  font-weight: 700;

                  &:hover,
                  &:focus {
                    background-color: var(--grey20);
                  }
                }

                &.ant-btn-primary {
                  background: var(--primaryGardient);
                  border-radius: 12px;
                  font-weight: 700;

                  &:hover,
                  &:focus {
                    background: var(--primaryGardient1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-tree {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #454b4d;

  &.ant-tree-directory {
    .ant-tree-treenode-selected {
      &::before {
        background: rgba(0, 191, 255, 0.1);
        border-radius: 0px 12px 12px 0px;
        bottom: 0;
      }

      &:hover {
        &::before {
          background: rgba(175, 161, 161, 0.15);
          border-radius: 0px 12px 12px 0px;
          bottom: 0;
        }
      }
    }

    .ant-tree-treenode {
      &:hover {
        &::before {
          border-radius: 0px 12px 12px 0px;
          background: rgba(175, 161, 161, 0.15);
          bottom: 0;
        }
      }

      .ant-tree-node-content-wrapper.ant-tree-node-selected {
        .ant-tree-iconEle {
          svg {
            path {
              fill: #33aaff;
              stroke: #33aaff;
            }
          }
        }

        color: #33aaff;

        &:hover {
          color: #454b4d;
        }
      }
    }

    .ant-tree-node-content-wrapper {
      &.ant-tree-node-selected {
        color: #33aaff;

        &::before {
          background: rgba(0, 191, 255, 0.1);
          border-radius: 0px 12px 12px 0px;
          bottom: 0;
        }

        &:hover {
          &::before {
            background: rgba(175, 161, 161, 0.15);
            border-radius: 0px 12px 12px 0px;
            bottom: 0;
          }
        }
      }
    }

    .ant-tree-treenode {
      color: #454b4d;

      .ant-tree-node-content-wrapper {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #454b4d;
      }
    }
  }

  .ant-tree-treenode {
    padding: 6px 0;

    .ant-tree-node-content-wrapper {
      display: flex;
      align-items: center;

      .ant-tree-iconEle {
        margin-right: 18px;
      }
    }

    .ant-tree-switcher {
      width: 18px;
    }
  }
}

.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.ant-input {
  padding: 15px 11px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: var(--primaryColor);
}

.ant-select {
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    height: 56px;
    display: flex;
    align-items: center;
  }

  .ant-select-arrow {
    color: var(--primaryColor);
  }
}

.ant-select-multiple .ant-select-selection-item {
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  border-radius: 92px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 8px 14px;
  height: 28px;

  .ant-select-selection-item-content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 146.19%;
    display: flex;
    align-items: center;
    color: #646464;
  }

  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover,
  &:focus {
    border: 1px solid var(--primaryColor);
  }
}

.ant-picker {
  padding: 15px 11px 15px;

  .ant-picker-input {
    input {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--primaryColor);
    }
  }
}

.ant-progress-bg {
  background-color: #ecaa51;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #1c9a54;
}

.ant-pagination {
  display: flex;

  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-pagination-item-link {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #264f81;
    }

    &.ant-pagination-item-active,
    &:hover,
    &:focus {
      background: #264f81;
      border: 1px solid #264f81;

      .ant-pagination-item-link {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .ant-pagination-item {
    border: 1px solid var(--primaryColor);
    box-sizing: border-box;
    background-color: #ffffff;
    border-color: var(--primaryColor);
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #264f81;
    }

    &.ant-pagination-item-active,
    &:hover,
    &:focus {
      background: #264f81;
      border: 1px solid #264f81;

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8);
}

.ant-card-bordered {
  border: 1px solid #dadada;
}

.modal-video-movie-wrap {
  background-color: transparent !important;
}
